import React from 'react';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Form,
    Button
} from "reactstrap";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import { useWorkingStates } from 'contexts/WorkingStatesContext';

const countries = {
    data: [
        { name: "Select country...", iso: "" },
        { name: "Australia", iso: "AUS" },
        { name: "Austria", iso: "AUT" },
        { name: "Denmark", iso: "DNK" },
        { name: "Estonia", iso: "EST" },
        { name: "Germany", iso: "DEU" },
        { name: "Latvia", iso: "LVA" },
        { name: "Poland", iso: "POL" },
        { name: "Spain", iso: "ESP" },
        { name: "Ukraine", iso: "UKR" },
        { name: "Czech Republic", iso: "CZE" },
        { name: "United Kingdom", iso: "GBR" },
        { name: "Romania", iso: "ROU" },
        { name: "Switzerland", iso: "CHE" },
        { name: "Netherlands", iso: "NLD" },
        { name: "Slovakia", iso: "SVK" },
        { name: "PL-Energy", iso: "PL-Energy" },
    ]
}

function DeviceInfoVariableData({
    textAreaValue,
    handleTextAreaValue,
    deviceNameValue,
    locationNameValue,
    handleLocationNameValue,
    latitudeValue,
    handleLatitudeValue,
    longitudeValue,
    handleLongitudeValue,
    handleSubmit,
    simCardValue,
    handleSimCardValue,
    errorMachineState,
    errorCountryState,
    rangeLatitudeState,
    rangeLongitudeState,
    selectedCountryValue,
    selectedStateValue,
    handleSelectedCountryValue,
    handleSelectedStateValue,
    mutationInfoError,
    mutationInfoSuccess,
    mutationInfoLoading,
    change,
    requiredNameState

}) {
    const workingStates = useWorkingStates();

    return (
        <>
            <Form>
                <Row>
                    <Col md="12">
                        <label>Device Name:</label>
                        <FormGroup className={requiredNameState}>
                            <Input
                                type="text"
                                maxLength="255"
                                value={deviceNameValue}
                                // onChange={handleDeviceNameValue}
                                onChange={(e) => change(e, "requiredName", "lengthName", 1)}
                                name="requiredName"
                                placeholder='Name'
                                autoComplete="off" />
                            {requiredNameState === "has-danger" && deviceNameValue === "" ? (
                                <label className="error">
                                    This field is required.
                                </label>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <label>Location:</label>
                        <FormGroup>
                            <Input
                                type="text"
                                maxLength="255"
                                value={locationNameValue}
                                onChange={handleLocationNameValue}
                                placeholder="Location"
                                autoComplete="off" 
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <label>Latitude</label>
                        <FormGroup className={rangeLatitudeState}>
                            <Input type="number" autoComplete="off" step={0.0001} min="-90" max="90" name="latitude" placeholder="0.0000" value={latitudeValue} onChange={handleLatitudeValue} />
                            {rangeLatitudeState === "has-danger" ? (
                                <label className="error">
                                    Please enter a value between -90 and 90.
                                </label>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <label>Longitude</label>
                        <FormGroup className={rangeLongitudeState}>
                            <Input type="number" autoComplete="off" step={0.0001} min="-180" max="180" name="longitude" placeholder="0.0000" value={longitudeValue} onChange={handleLongitudeValue} />
                            {rangeLongitudeState === "has-danger" ? (
                                <label className="error">
                                    Please enter a value between -180 and 180.
                                </label>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <label>Country select</label>
                        <FormGroup className={errorCountryState}>
                            <Input
                                id="countrySelect"
                                name="country-select"
                                type="select"
                                className='select-form-control'
                                onChange={handleSelectedCountryValue}
                                value={selectedCountryValue}
                            >
                                {countries.data.map((country, index) =>
                                    <option key={`country-${index}`} value={country.iso} className='select-option-form-control'>
                                        {country.name}
                                    </option>
                                )}
                            </Input>
                            {errorCountryState === "has-danger" ? (
                                <label className="error">
                                    Please select country.
                                </label>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <label>Machine state</label>
                        <FormGroup className={errorMachineState}>
                            <Input
                                id="machineStateSelect"
                                name="machine-state-select"
                                type="select"
                                className='select-form-control'
                                onChange={handleSelectedStateValue}
                                value={selectedStateValue}
                            >
                                <option key="state-" value="" className='select-option-form-control'>
                                    Select state...
                                </option>
                                {workingStates && workingStates.map((state, index) =>
                                    <option key={`state-${index}`} value={state.id} className='select-option-form-control'>
                                        {state.name}
                                    </option>
                                )}
                            </Input>
                            {errorMachineState === "has-danger" ? (
                                <label className="error">
                                    Please select machine state.
                                </label>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <label>SIM Card Number</label>
                        <FormGroup>
                            <Input type="text" autoComplete="off" maxLength="255" value={simCardValue} onChange={handleSimCardValue} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <label>Notes:</label>
                        <FormGroup>
                            <Input type="textarea" maxLength="255" autoComplete="off" className='textarea-form-control' value={textAreaValue} onChange={handleTextAreaValue} />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <div className='submit-button-row'>
                <Button className="btn-fill" color={mutationInfoError ? "danger" : mutationInfoSuccess ? "success" : 'primary'} type="submit"
                    onClick={(event) => handleSubmit(event)}
                >
                    {mutationInfoLoading ? <SuspenseComponentText /> :
                        mutationInfoError ? (

                            `Failure`

                        ) : mutationInfoSuccess ? (

                            `Success`

                        ) :
                            `Submit`
                    }
                </Button>
            </div>

        </>
    );
}

export default DeviceInfoVariableData;
