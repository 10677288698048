import React, { useContext } from "react";
import { useQuery } from "react-query";
import { GetDeviceWorkingStates } from 'API';

const WorkingStatesContext = React.createContext();

export const useWorkingStates = () => {
    return useContext(WorkingStatesContext)
}

export function WorkingStatesContextProvider({ children }) {
    const { data, isLoading, error } = useQuery("DeviceWorkingStatesData", GetDeviceWorkingStates, {
        staleTime: Infinity,
        select: (response) => response?.data?.data || []
    });

    return (
        <WorkingStatesContext.Provider value={ data }>
            {children}
        </WorkingStatesContext.Provider>
    )
}


