import React, { useState } from 'react';
import { getDeviceLogs, getDeviceLogsTEST } from "API";
import { useQuery } from 'react-query';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ReactTable from "components/ReactTable/ReactTableConfig.js";
import moment from 'moment';

import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
} from "reactstrap";
const DeviceLogsTable = ({ serial, valueDateRange, loading }) => {
    const [data, setData] = useState([]);
    const dataLogs = useQuery('Logs', () => getDeviceLogs(serial, valueDateRange[0], valueDateRange[1]), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setData(
                data.data.logs?.map((prop, key) => {
                    return {
                        id: prop.id,
                        lvl: prop.lvl,
                        msg: prop.msg,
                        date: moment.utc(prop.date).local().format("YYYY-MM-DD HH:mm:ss"),
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">

                            </div>
                        )
                    };
                })
            );
        }
    });

    const dataLogsTEST = useQuery('LogsTEST', () => getDeviceLogsTEST(serial, valueDateRange[0], valueDateRange[1]), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
        }
    });

    const getTrProps = (rowInfo) => {
        return {
            style: {
                background: 'green',
                color: 'white'
            }
        }
    }

    return (
        <Row>
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Logs</CardTitle>
                    </CardHeader>
                    <CardBody>
                        {
                            loading || dataLogs.isLoading || dataLogs.isFetching ? <SuspenseComponent /> :
                                <div className='logs-table'>

                                    <ReactTable
                                        data={data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Date",
                                                accessor: "date",
                                                sortable: true,
                                                style: { width: '20%' },
                                                sortMethod: (a, b) => {
                                                    const dateA = new Date(a);
                                                    const dateB = new Date(b);
                                                    if (dateA > dateB) return -1;
                                                    if (dateA < dateB) return 1;
                                                    return 0;
                                                },
                                            },
                                            {
                                                Header: "Message",
                                                style: { width: '100%' },
                                                accessor: "msg"
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                style: { width: '20%' },
                                                filterable: false
                                            }

                                        ]}
                                        defaultPheartbeatSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        getTrProps={getTrProps}
                                        className="-striped -highlight"
                                    />
                                </div>
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DeviceLogsTable;
