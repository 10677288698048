import React from 'react';
import AppRouter from './AppRouter';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { DataDeviceAccessProvider } from './contexts/DataDeviceContext'
import { IntervalDataProvider } from './contexts/IntervalDataContext'
import { WorkingStatesContextProvider } from './contexts/WorkingStatesContext'


const queryClient = new QueryClient()
// import { AuthProvider } from './contexts/AuthContext'
// import { LangProvider } from './contexts/LangContext'
// import { UserProvider } from './contexts/UserContext'

export default function App() {

  return (
    <>
      {/* <LangProvider> */}
      {/* <AuthProvider> */}
      {/* <UserProvider> */}
      <QueryClientProvider client={queryClient}>
        <DataDeviceAccessProvider>
          <IntervalDataProvider>
            <WorkingStatesContextProvider>
              <AppRouter />
            </WorkingStatesContextProvider>
          </IntervalDataProvider>
        </DataDeviceAccessProvider>
      </QueryClientProvider>
      {/* </UserProvider> */}
      {/* </AuthProvider> */}
      {/* </LangProvider> */}
    </>
  )
};